import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Alert, Box, Grid, Snackbar, ToggleButton, Switch, ToggleButtonGroup, Stack, Button, Select, MenuItem, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import { projectManageAxios as pmAxios } from '../../../utils/AxiosUtility';
import axios from "axios";
import CoordinationShippingDetails from "./ShippingDetails";
import CoordinationCraneDetails from "./CraneDetails";
import LiftDetails from "./LiftDetails";
import NitroDetails from "./NitroDetails";
import InvoicingDetails from "./InvoicingDetails";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import InputAdornment from '@mui/material/InputAdornment';
import dayjs from "dayjs";
import NumericInput from "../../../components/NumericInput";
import { useMsal } from "@azure/msal-react";

export function CoordinationTask() {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pm, setPM] = useState("Allen Yokom");
  const [installDate, setInstallDate] = useState(dayjs());
  const [shipDate, setShipDate] = useState(dayjs());
  const [shipDateConfirm, setShipDateConfirm] = useState(false);
  const [productsShipped, setProductsShipped] = useState(false);
  const [cogs, setCOGS] = useState();
  const [siteContactCallDate, setSiteContactCallDate] = useState(dayjs());
  const [preInstallationConfirmation, setPreInstallationConfirmation] = useState(false);
  const [customerHandoffCallDate, setCustomerHandoffCallDate] = useState(dayjs());
  const [customerHandoffCallCompletedDate, setCustomerHandoffCallCompletedDate] = useState();
  const [customerHandoffCallCompletedSwitch, setCustomerHandoffCallCompletedSwitch] = useState(false);
  const [installComplete, setInstallComplete] = useState(false);
  const [installCompleteDate, setInstallCompleteDate] = useState(dayjs());
  const [installCompleteMessageStatus, setInstallCompleteMessageStatus] = useState(false);
  const [activityCompleteMessageStatus, setActivityCompleteMessageStatus] = useState(false);
  const [activationComplete, setActivationComplete] = useState(false);
  const [activationCompleteDate, setActivationCompleteDate] = useState(dayjs());
  const [readyToBill, setReadyToBill] = useState();
  const [readyToBillCompleted, setReadyToBillCompleted] = useState();
  const [shippingVerified, setShippingVerified] = useState("");
  const [craneVerified, setCraneVerified] = useState("");
  const [liftVerified, setLiftVerified] = useState("");
  const [nitroVerified, setNitroVerified] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogText, setDialogText] = useState("");
  const [confirmAction, setConfirmAction] = useState(null);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const [truckingScheduled, setTruckingScheduled] = useState(false);
  const [products, setProducts] = useState([]);
  const [productTypes, setProductTypes] = useState([]);

  const availablePMs = [
    { id: 0, name: "Allen Yokom" },
    { id: 1, name: "Kiley Galesewicz" },
    { id: 2, name: "Maurice Johnson" },
    { id: 3, name: "Roman Dowhaniuk", },
  ];

  const handlePmSelect = (event) => {
    setPM(event.target.value);
  };

  const handleReadyToBill = (event, value) => {
    if (value !== null) {
      if (value) {
        setDialogTitle("Ready to Bill");
        setDialogText("Please confirm that this project is ready to bill.");
        setConfirmAction('readyToBill');
        setOpenDialog(true);
      }
      else {
        setReadyToBill(null);
        setReadyToBillCompleted(false);
      }
    }
  };

  const handleReadyToBillCompleted = (event, value) => {
    if (value !== null) {
      if (value === false) {
        setReadyToBillCompleted(false);
        return;
      }
      setDialogTitle("Bill Completed");
      setDialogText("Please confirm that billing for this project has completed.");
      setConfirmAction('readyToBillCompleted');
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setConfirmAction(null);
  };
  
  const handleConfirmDialog = () => {
    if (confirmAction === 'readyToBill') {
      setReadyToBill(new Date());
    } else if (confirmAction === 'readyToBillCompleted') {
      setReadyToBillCompleted(true);
    }
    setOpenDialog(false);
    setConfirmAction(null);
  };

  const handleTruckingScheduled = (event, truckingScheduled) => {
    setTruckingScheduled(truckingScheduled);
  }

  const handleProductsShipped = (event, shipped) => {
    setProductsShipped(shipped);
  }

  const handleInstallComplete = (event, insComplete) => {
    if (insComplete && !installComplete) {
      setInstallCompleteDate(new Date());
      setInstallCompleteMessageStatus(true);
    }
    setInstallComplete(insComplete);
  }

  const handleActivationComplete = (event, actComplete) => {
    if (actComplete && !activationComplete) {
      setActivationCompleteDate(new Date());
      setActivityCompleteMessageStatus(true);
    }
    setActivationComplete(actComplete);
  }

  const handleSiteContactCall = (date) => {
    setSiteContactCallDate(date);
  };

  const handleHandoffCall = (date) => {
    setCustomerHandoffCallDate(date);
  };

  const handlepreInstallationConfirmation = (event, preInstallConfirm) => {
    if (preInstallConfirm !== null) {
      setPreInstallationConfirmation(preInstallConfirm);
    }
  };

  const handleHandoffConfirmed = (event, confirmed) => {
    if (confirmed !== null) {
      if (confirmed === true) {
        setCustomerHandoffCallCompletedDate(dayjs().format('YYYY-MM-DD'));
      }
      setCustomerHandoffCallCompletedSwitch(confirmed);
    }
  };

  const handleShipDate = (date) => {
    setShipDate(date);
  }

  const handleShipDateConfirm = (event, confirm) => {
    setShipDateConfirm(confirm);
  }

  const handleSubTaskSave = () => {
    fetchData();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Array.isArray(products)) {
      const prodTypes = products.map(product => product.productType);
      setProductTypes(prodTypes);
    } else {
      console.error("Products is not an array:", products);
    }
  }, [products]);

  const fetchData = async () => {
    try {
      const tasksData = pmAxios.get(`api/Coordination/taskList/${searchParams.get("id")}`);
      const projectData = pmAxios.get(`api/Projects/project/${searchParams.get("id")}`);
      const productData = pmAxios.get(`api/Projects/products/${searchParams.get("id")}`);
      const shippingData = pmAxios.get(`api/Coordination/shippingDetails/${searchParams.get("id")}`);
      const craneData = pmAxios.get(`api/Coordination/craneDetails/${searchParams.get("id")}`);
      const liftData = pmAxios.get(`api/Coordination/liftDetails/${searchParams.get("id")}`);
      const nitroData = pmAxios.get(`api/Coordination/nitroDetails/${searchParams.get("id")}`);

      const [tasksResponse, projectResponse, productResponse, shippingResponse, craneResponse, liftResponse, nitroResponse] = await axios.all([
        tasksData,
        projectData,
        productData,
        shippingData,
        craneData,
        liftData,
        nitroData
      ]);

      const offsetDate = (date) => {
        return date ? new Date(date.getTime() - (date.getTimezoneOffset() * 60000)) : null;
      }

      setPM(tasksResponse.data.pmAssigned);
      setShippingVerified(shippingResponse.data);
      setCraneVerified(craneResponse.data);
      setLiftVerified(liftResponse.data);
      setNitroVerified(nitroResponse.data);
      setInstallDate(projectResponse.data.installDate ? offsetDate(new Date(projectResponse.data.installDate)) : null);
      setShipDate(projectResponse.data.shippedDate ? offsetDate(new Date(projectResponse.data.shippedDate)) : null);
      setShipDateConfirm(projectResponse.data.shipDateConfirmed);
      setProductsShipped(projectResponse.data.productsShipped);
      setCOGS(tasksResponse.data.cogs);
      setSiteContactCallDate(tasksResponse.data.siteContactCallDate ? offsetDate(new Date(tasksResponse.data.siteContactCallDate)) : null);
      setPreInstallationConfirmation(tasksResponse.data.preInstallationConfirmation);
      setCustomerHandoffCallDate(tasksResponse.data.customerHandoffCallDate ? offsetDate(new Date(tasksResponse.data.customerHandoffCallDate)) : null);
      setInstallComplete(projectResponse.data.installComplete);
      setInstallCompleteDate(tasksResponse.data.installCompleteDate ? offsetDate(new Date(tasksResponse.data.installCompleteDate)) : null);
      setActivationComplete(tasksResponse.data.activationComplete);
      setActivationCompleteDate(tasksResponse.data.activationCompleteDate ? offsetDate(new Date(tasksResponse.data.activationCompleteDate)) : null);
      setCustomerHandoffCallCompletedDate(tasksResponse.data.customerHandoffCallCompletedDate ? dayjs(tasksResponse.data.customerHandoffCallCompletedDate).format('YYYY-MM-DD') : null);
      setCustomerHandoffCallCompletedSwitch(tasksResponse.data.customerHandoffCallCompleted);
      setReadyToBill(tasksResponse.data.readyToBill ? tasksResponse.data.readyToBill : null);
      setReadyToBillCompleted(tasksResponse.data.readyToBillCompleted);
      setTruckingScheduled(tasksResponse.data.truckingScheduled);
      setProducts(Array.isArray(productResponse.data) ? productResponse.data : []);

      setLoading(false);
    } catch (error) {
      console.error(error);
      if (error.message) {
        alert(error.message);
      } else {
        alert(`Error fetching coordination task details.`);
      }
    }
  };

  const saveTasks = () => {

    const updatedTasks = {
      projectId: searchParams.get("id"),
      pmAssigned: pm,
      preInstallationConfirmation: preInstallationConfirmation,
      truckingScheduled: truckingScheduled,
      productsShipped: productsShipped,
      installComplete: installComplete,
      installCompleteDate: installComplete ? dayjs(installCompleteDate) : null,
      shipDateConfirmed: shipDateConfirm,
      activationComplete: activationComplete,
      activationCompleteDate: activationComplete ? dayjs(activationCompleteDate) : null,
      customerHandoffCallDate: dayjs(customerHandoffCallDate),
      customerHandoffCallCompleted: customerHandoffCallCompletedSwitch,
      readyToBill: readyToBill,
      readyToBillCompleted: readyToBillCompleted,
      cogs: cogs
    };

    if (customerHandoffCallDate != null) {
      updatedTasks.customerHandoffCallDate = dayjs(customerHandoffCallDate);
    }

    if (siteContactCallDate !== null) {
      updatedTasks.siteContactCallDate = dayjs(siteContactCallDate);
    }

    if (customerHandoffCallCompletedDate !== null) {
      updatedTasks.customerHandoffCallCompletedDate = dayjs(customerHandoffCallCompletedDate).format('YYYY-MM-DD');
    }

    pmAxios.post("/api/Coordination/saveTasks", updatedTasks)
      .then(() => {
        const shipDateData = {
          projectId: searchParams.get("id"),
          shippedDate: dayjs(shipDate)
        };

        if (installCompleteMessageStatus) {
          const installNote = {
            UserName: activeAccount.name.split(' ')[0],
            Notes: "Install Completed.",
            DateTime: new Date(new Date().getTime()),
            ProjectId: searchParams.get("id")
          };

          pmAxios.post("/api/ActivityFeed/saveNote", installNote)
            .then(() => {
              setInstallCompleteMessageStatus(false);
            })
        }

        if (activityCompleteMessageStatus) {
          const activityNote = {
            UserName: activeAccount.name.split(' ')[0],
            Notes: "Activation Completed.",
            DateTime: new Date(new Date().getTime()),
            ProjectId: searchParams.get("id")
          };

          pmAxios.post("/api/ActivityFeed/saveNote", activityNote)
            .then(() => {
              setInstallCompleteMessageStatus(false);
            })
        }

        pmAxios.put("/api/Coordination/updateShipDate", shipDateData)
          .then(() => {
            setSnackbarOpen(true);
          })
          .catch((error) => {
            console.error(error);
            alert(error.response?.data || "Error saving tasks. Please try again.");
          })
          .finally(() => {
            fetchData();
          });
      });
  };

  return (
    <>
      {loading ?
        <Box sx={{ minHeight: "1137px" }} justifyContent="center" alignContent="center">
          <CircularProgress variant="indeterminate" />
        </Box>
        :
        <>
          <Typography variant="h5" align="center" gutterBottom>
            Tasks
          </Typography>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Project Manager
              </Typography>

              <Select
                labelId="pm-label"
                id="pm-select"
                value={pm}
                label="Unit"
                onChange={handlePmSelect}
                sx={{ padding: '0px', maxHeight: "45px" }}
              >
                {availablePMs.map((pm) => (
                  <MenuItem key={pm.id} value={pm.name}>
                    {pm.name}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Install Date
              </Typography>

              <Box style={{ flexGrow: '1', minWidth: '60%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label={"Scheduled Install Date"}
                    value={dayjs(installDate)}
                    disabled
                  />
                </LocalizationProvider>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Ship Date
              </Typography>

              <Box style={{ flexGrow: '1', minWidth: '60%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label={"Scheduled Ship Date"}
                    onChange={handleShipDate}
                    value={dayjs(shipDate)}
                    slotProps={{
                      field: { clearable: true },
                    }}
                  />
                </LocalizationProvider>

                <ToggleButtonGroup
                  color="primary"
                  value={shipDateConfirm}
                  exclusive
                  onChange={handleShipDateConfirm}
                  aria-label="Ship Date Confirmation"
                  sx={{ paddingRight: "10%", paddingLeft: "5%" }}
                >
                  <ToggleButton color="success" value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Shipping
              </Typography>

              <CoordinationShippingDetails />

              {shippingVerified.productsShipped
                ?
                <Typography variant="h6" color="green" >Verified <CheckIcon style={{ verticalAlign: "middle" }} /></Typography>
                :
                null
              }
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Crane
              </Typography>

              <CoordinationCraneDetails onSave={handleSubTaskSave} />

              {craneVerified.craneArrivalConfirmation
                ?
                <Typography variant="h6" color="green" >Verified <CheckIcon style={{ verticalAlign: "middle" }} /></Typography>
                :
                null
              }
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Lifts
              </Typography>

              <LiftDetails onSave={handleSubTaskSave} />

              {liftVerified.dateOutConfirmation
                ?
                <Typography variant="h6" color="green" >Verified <CheckIcon style={{ verticalAlign: "middle" }} /></Typography>
                :
                null
              }
            </Stack>
          </Grid>

          {productTypes.includes('BSU') && (
            <Grid item xs={12} style={{ paddingBottom: '5%' }}>
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="h6"
                  style={{ minWidth: '35%' }}
                >
                  Nitro
                </Typography>

                <NitroDetails onSave={handleSubTaskSave} />

                {nitroVerified.confirmationNumber
                  ?
                  <Typography variant="h6" color="green" >Verified <CheckIcon style={{ verticalAlign: "middle" }} /></Typography>
                  :
                  null
                }
              </Stack>
            </Grid>
          )}

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ width: "35%" }}
              >
                Pre Installation Call
              </Typography>

              <Box style={{ flexGrow: '1', minWidth: '60%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label={"Site Contact Call Date"}
                    onChange={handleSiteContactCall}
                    value={dayjs(siteContactCallDate)}
                    slotProps={{
                      field: { clearable: true },
                    }}
                  />
                </LocalizationProvider>

                <ToggleButtonGroup
                  color="primary"
                  value={preInstallationConfirmation}
                  exclusive
                  onChange={handlepreInstallationConfirmation}
                  aria-label="Preinstallation Call Confirmation"
                  sx={{ paddingRight: "10%", paddingLeft: "5%" }}
                >
                  <ToggleButton color="success" value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%'}}
              >
                Trucking Scheduled
              </Typography>

              <Box style={{ flexGrow: '1', minWidth: '60%'}}>
                <Switch
                  checked={truckingScheduled}
                  exclusive = {true}
                  onChange={handleTruckingScheduled}
                  aria-label="TruckingScheduled"
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ width: "35%" }}
              >
                Products Shipped
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={productsShipped}
                exclusive
                onChange={handleProductsShipped}
                aria-label="Shipped"
                style={{ flexGrow: '1', minWidth: '60%' }}
              >
                <ToggleButton color="success" value={true}>
                  Yes
                </ToggleButton>
                <ToggleButton value={false}>No</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ width: "35%" }}
              >
                Install Complete
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={installComplete}
                exclusive
                onChange={handleInstallComplete}
                aria-label="Approval"
                style={{ flexGrow: '1', minWidth: '60%' }}
              >
                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                <ToggleButton value={false}>No</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ width: "35%" }}
              >
                Activation Complete
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={activationComplete}
                exclusive
                onChange={handleActivationComplete}
                aria-label="Approval"
                style={{ flexGrow: '1', minWidth: '60%' }}
              >
                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                <ToggleButton value={false}>No</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ width: "35%" }}
              >
                FR System Tutorial
              </Typography>

              <Box style={{ flexGrow: '1', minWidth: '60%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label={"FR System Tutorial Date"}
                    onChange={handleHandoffCall}
                    value={dayjs(customerHandoffCallDate)}
                    slotProps={{
                      field: { clearable: true },
                    }}
                  />
                </LocalizationProvider>

                <ToggleButtonGroup
                  color="primary"
                  value={customerHandoffCallCompletedSwitch}
                  exclusive
                  onChange={handleHandoffConfirmed}
                  aria-label="FR System Tutorial Confirmation"
                  sx={{ paddingRight: "10%", paddingLeft: "5%" }}
                >
                  <ToggleButton color="success" value={true}>Yes</ToggleButton>
                  <ToggleButton value={false}>No</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ width: "35%" }}
              >
                Cost of Goods Sold
              </Typography>

              <Box style={{ flexGrow: '1', minWidth: '60%' }}>
                <TextField
                  label="COGS"
                  value={cogs}
                  onChange={(e) => setCOGS(e.target.value)}
                  name="cogs"
                  id="cogs-price-input"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                    inputComponent: NumericInput,
                  }}
                  variant="outlined"
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ minWidth: '35%' }}
              >
                Invoicing
              </Typography>

              <Box sx={{ flexGrow: '1', minWidth: "30%" }}>
                <InvoicingDetails />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
          <Stack direction="row" alignItems="center">
            <Typography
              variant="h6"
              style={{ width: "35%" }}
            >
              Ready To Bill
            </Typography>

            <ToggleButtonGroup
              color="primary"
              value={readyToBill !== null}
              exclusive
              onChange={(event, value) => {
                handleReadyToBill(event, value);
              }}
              aria-label="Ready to bill"
              style={{ flexGrow: '1', minWidth: '60%' }}
            >
              <ToggleButton color="success" value={true}>Yes</ToggleButton>
              <ToggleButton value={false}>No</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>

        {readyToBill
          ?
          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h6"
                style={{ width: "35%" }}
              >
                Bill Completed
              </Typography>

              <ToggleButtonGroup
                color="primary"
                value={readyToBillCompleted}
                exclusive
                onChange={(event, value) => handleReadyToBillCompleted(event, value)}
                aria-label="Ready to bill completed"
                style={{ flexGrow: '1', minWidth: '60%' }}
              >
                <ToggleButton color="success" value={true}>Yes</ToggleButton>
                <ToggleButton value={false}>No</ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Grid>
          :
          null}

          <Grid item xs={12} style={{ paddingBottom: "1%" }}>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={saveTasks}>Save</Button>
            </Box>
          </Grid>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Saved Tasks Successfully!
            </Alert>
          </Snackbar>

          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
              {dialogText}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
              <Button onClick={handleConfirmDialog} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </>
      }
    </>
  );
}