import React, { useEffect, useState } from "react";
import { Stack, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField} from "@mui/material";
import moment from "moment";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";
import { Authenticated } from "../AuthenticatedComponent";
import { MaterialReactTable, useMaterialReactTable, } from 'material-react-table';

function createPtoRequestData(requestid, userid, timesheetid, firstname, lastname, department, teamnumber, requestdate, status, days, dayout, returndate, ptotype, ptonote) {
  return {
      requestid,
      userid,
      timesheetid,
      firstname,
      lastname,
      department,
      teamnumber,
      requestdate,
      status,
      days,
      dayout,
      returndate,
      ptotype,
      ptonote
  }
}

function createEmployeeData(userid, ptototal, ptopending, ptoused) {
  return {
    userid,
    ptototal,
    ptopending,
    ptoused
  }
}

function getWeekRange(date) {
  return {
      from: moment(date)
          .startOf('week')
          .toDate(),
      to: moment(date)
          .endOf('week')
          .toDate(),
  };
}

const managerRole = ["Service_TimeSheet_Manager", "SI_TimeSheet_Manager"];
const payrollRole = ["Payroll_Processors"];
const adminRole = ["Admin"];
const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

export default function PTOReqeustTable(request) {

  const [currentRequest, setCurrentRequest] = useState();
  const [employeeList, setEmployeeList] = useState([]);
  const [rows, setRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogText, setDialogText] = useState("");
  const [cancelReason, setCancelReason] = useState("");
  const [confirmAction, setConfirmAction] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPtoRequestData();
    // eslint-disable-next-line
  }, [request.listener])

  useEffect(() => {
    setLoading(true);
    const endpoint = 'api/Employee/employee';
    axios.get(endpoint)
        .then((response) => {
            loadCurrentEmployee(response.data);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    // eslint-disable-next-line
  }, [currentRequest]);

  const loadCurrentEmployee = (employees) => {
      let newRows = [];
      if (employees.length > 0) {
          employees.forEach(e => {
              if (currentRequest.userid === e.userId) {
                  newRows.push(createEmployeeData(
                      e.userId,
                      e.ptoTotal,
                      e.ptoPending,
                      e.ptoUsed
                  ))
              }
          })
      }
      setEmployeeList(newRows);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setConfirmAction(null);
  };

  const handleConfirmDialog = () => {
    // update PTO request and add PTO time log
    var updatedPto = {
      userId: currentRequest.userid,
      ptoTotal: employeeList[0].ptototal,
      ptoPending: employeeList[0].ptopending,
      ptoUsed: employeeList[0].ptoused,
    }
    if (confirmAction === "readyToApprove") {
      const updatedStatus = {
        requestId: currentRequest.requestid,
        ptoRequestStatus: "Approved",
        ptoNote: null,
      };

      axios.post("/api/TimeSheet/approvePtoRequest", updatedStatus)
          .then(() => {
          })
          .catch((error) => {
            console.error(error);
            alert(error.response?.data || "Error submitting timesheet. Please try again.");
          })
          .finally(() => {
            handleAddTime(offsetDate(new Date(currentRequest.dayout)), offsetDate(new Date(currentRequest.returndate)))
            fetchPtoRequestData();
            request.updateSheet();
            handleCloseDialog();
            setLoading(false);
          });

      const requestedTime = (getBusinessDatesCount(offsetDate(new Date(currentRequest.dayout)), offsetDate(new Date(currentRequest.returndate)))) * 8

      updatedPto = {
        userId: currentRequest.userid,
        ptoTotal: employeeList[0].ptototal,
        ptoPending: employeeList[0].ptopending ? employeeList[0].ptopending - requestedTime : employeeList[0].ptopending,
        ptoUsed: employeeList[0].ptoused ? employeeList[0].ptoused + requestedTime : requestedTime,
      };

    } else if (confirmAction === "cancel" || confirmAction === "decline") {
      const updatedStatus = {
        requestId: currentRequest.requestid,
        ptoRequestStatus: "Canceled",
        ptoNote: cancelReason ? cancelReason : currentRequest.ptonote,
      };

      axios.post("/api/TimeSheet/cancelPtoRequest", updatedStatus)
          .then(() => {
          })
          .catch((error) => {
            console.error(error);
            alert(error.response?.data || "Error canceling pto request. Please try again.");
          })
          .finally(() => {
            fetchPtoRequestData();
            request.updateSheet();
            handleCloseDialog();
            setLoading(false);
          });

      const requestedTime = (getBusinessDatesCount(offsetDate(new Date(currentRequest.dayout)), offsetDate(new Date(currentRequest.returndate)))) * 8

      if (confirmAction === "cancel") {
        updatedPto = {
          userId: currentRequest.userid,
          ptoTotal: employeeList[0].ptototal,
          ptoPending: employeeList[0].ptopending,
          ptoUsed: employeeList[0].ptoused ? employeeList[0].ptoused - requestedTime : employeeList[0].ptoused,
        };
      } else if (confirmAction === "decline") {
        updatedPto = {
          userId: currentRequest.userid,
          ptoTotal: employeeList[0].ptototal,
          ptoPending: employeeList[0].ptopending ? employeeList[0].ptopending - requestedTime : employeeList[0].ptopending,
          ptoUsed: employeeList[0].ptoused,
        };
      }
      
    }
    axios
        .post('api/Employee/loadEmployeePto', updatedPto)
        .then((response) => {
        })
        .catch((error) => {
            console.log(error);
            if (error.response && error.response.data) {
                alert(error.response.data);
            } else {
                alert("Error updating PTO Request. Please try again.");
            }
        })
        .finally(() => {
            setLoading(false);
        });

    handleCloseDialog();
  };

  const handleAddTime = (startDate, endDate) => {
    var curDate = new Date(startDate.getTime());
    while (curDate <= endDate-1) {
        const dayOfWeek = curDate.getDay();
        if(dayOfWeek !== 0 && dayOfWeek !== 6) {
          const newTimeLog = {
            empUserId: currentRequest.userid,
            timesheetDate: new Date(curDate).toISOString().substring(0, 10),
            clockIn: null,
            clockOut: null,
            hourType: "PTO",
            hoursWorked: 8,
            tsDescription: currentRequest.ptotype,
            tsDay: weekdays[dayOfWeek],
            weekOf: new Date(getWeekRange(new Date(curDate)).from).toISOString().substring(0, 10)
        };
    
        axios
              .post("/api/TimeSheet/createTimeLog", newTimeLog)
              .then(() => {
            })
            .catch((error) => {
              console.error(error);
              alert(error.response?.data || "Error creating Timesheet Log. Please try again.");
            })
            .finally(() => {
              fetchPtoRequestData();
              request.updateSheet();
              setLoading(false);
            });
        }
        curDate.setDate(curDate.getDate() + 1);
    }
  }

  const handleApprove = (row) => {
    setCurrentRequest(row);
    setDialogTitle("Approve PTO Request?");
    setDialogText("PTO will automatically be added to the employee's timesheets.");
    setConfirmAction('readyToApprove');
    setOpenDialog(true);
  };

  const handleDecline = (row) => {
    setCurrentRequest(row);
    setDialogTitle("Cancel PTO Request?");
    setDialogText("PTO will not be added to the employee's timesheets or removed if previously approved.");
    setConfirmAction('decline');
    setOpenDialog(true);
  };

  const handleCancel = (row) => {
    setCurrentRequest(row);
    setDialogTitle("Cancel PTO Request?");
    setDialogText("PTO will not be added to the employee's timesheets or removed if previously approved.");
    setConfirmAction('cancel');
    setOpenDialog(true);
  };

  const getBusinessDatesCount = (startDate, endDate) => {
    let count = 0;
    var curDate = new Date(startDate.getTime());
    while (curDate < endDate) {
        const dayOfWeek = curDate.getDay();
        if(dayOfWeek !== 0 && dayOfWeek !== 6) count++;
        curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  }

  const fetchPtoRequestData = () => {
    setLoading(true);
    const endpoint = 'api/TimeSheet/ptoRequest';
    axios.get(endpoint)
      .then((response) => {
        loadRows(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchPtoRequestData();
    // eslint-disable-next-line
  }, []);

  const offsetDate = (date) => {
    return date ? new Date(date.getTime() + (date.getTimezoneOffset() * 60000)) : null;
  }

  const loadRows = (ptoRequests) => {

    let newRows = [];
    if (ptoRequests.length > 0) {
      ptoRequests.forEach(pr => {
        const offsetDate = (date) => {
          return date ? new Date(date.getTime() + (date.getTimezoneOffset() * 60000)) : null;
        }
        if (request.currentUserId === pr.employeeId || request.subordinateIdsList.includes(pr.employeeId) || Authenticated(payrollRole)) {
          newRows.push(createPtoRequestData(
            pr.requestId,
            pr.employeeId,
            pr.timesheetId,
            pr.firstName,
            pr.lastName,
            pr.department,
            pr.teamNumber,
            pr.requestDate ? offsetDate(new Date(pr.requestDate)).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }): "-",
            pr.ptoRequestStatus,
            pr.plannedDayOut && pr.plannedReturnDate ? getBusinessDatesCount(offsetDate(new Date(pr.plannedDayOut)), offsetDate(new Date(pr.plannedReturnDate))) : 0,
            pr.plannedDayOut ? offsetDate(new Date(pr.plannedDayOut)).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }): "-",
            pr.plannedReturnDate ? offsetDate(new Date(pr.plannedReturnDate)).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }): "-",
            pr.ptoType,
            pr.ptoNote,
          ))
        }
      })
    }
    setRows(newRows);
  }

  const columns =
    [
      {
        accessorKey: 'requestid',
        header: 'PTO Request',
        size: 200,
        enableColumnOrdering: false,
        Cell: ({ row }) => (
          <>
          {(Authenticated(managerRole) || Authenticated(adminRole)) && row.original.status === "Pending"
            ? (
              <Stack direction="row" width={"100%"} marginBottom={"10px"} >
                <Stack width="45%" >
                  <Button
                    variant="contained"
                    onClick={() => handleApprove(row.original)}
                    sx={{ padding: "0px" }}
                  >
                    Approve
                  </Button>
                </Stack>
                <Stack width="5%"/>
                <Stack width="45%" paddingRight="5%">
                  <Button
                    variant="contained"
                    onClick={() => handleDecline(row.original)}
                    sx={{ padding: "0px" }}
                  >
                    Decline
                  </Button>
                </Stack>
              </Stack>
            ) : (
              null
            )
          }
          {(Authenticated(managerRole) || Authenticated(adminRole)) && row.original.status === "Approved"
            ? (
              <Stack direction="row" width={"100%"} marginBottom={"10px"} >
                <Stack width="95%" paddingRight="5%">
                  <Button
                    variant="contained"
                    onClick={() => handleCancel(row.original)}
                    sx={{ padding: "0px" }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </Stack>
            ) : (
              null
            )
          }
          </>
        ),
      },
      {
        accessorKey: 'firstname',
        header: 'First Name',
        size: 80,
        enableColumnOrdering: false,
      },
      {
        accessorKey: 'lastname',
        header: 'Last Name',
        enableColumnOrdering: false,
        size: 80,
      },
      {
        accessorKey: 'requestdate',
        header: 'Submission Date',
        size: 120,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        size: 100,
        sortingFn: (a, b, columnId) => {
          if (b.original[columnId] === "Approved" && a.original[columnId] === "Canceled")
            return -1;
          else if (b.original[columnId] === "Canceled" && a.original[columnId] === "Approved")
            return 1;
          else if (b.original[columnId] === "Pending" && a.original[columnId] === "Approved")
            return -1;
          else if (b.original[columnId] === "Approved" && a.original[columnId] === "Pending")
            return 1;
          else if (b.original[columnId] === "Pending" && a.original[columnId] === "Canceled")
            return -1;
          else if (b.original[columnId] === "Canceled" && a.original[columnId] === "Pending")
            return 1;
          else
            return 0;
        },
      },
      {
        accessorKey: 'days',
        header: '# of Days',
        size: 80,
      },
      {
        accessorKey: 'dayout',
        header: 'Day Out',
        size: 100,
      },
      {
        accessorKey: 'returndate',
        header: 'Return Date',
        size: 100,
      },
      {
        accessorKey: 'ptotype',
        header: 'PTO Type',
        size: 100,
      },
      {
        accessorKey: 'ptonote',
        header: 'Additional Notes',
        size: 250,
      },
    ];

  const table = useMaterialReactTable({
    columns,
    data: rows,
    enableExpandAll: true,
    enableExpanding: true,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableStickyHeader: true,
    state: {
      showSkeletons: loading,
    },
    layoutMode: 'grid',
    paginationDisplayMode: 'pages',
    initialState: {
      density: 'compact',
      sorting: [
        {
          id: 'status',
          desc: true,
        },
        {
          id: 'requestdate',
          desc: true,
        },
        {
          id: 'lastname',
          desc: true,
        }
      ],
      columnPinning: { left: ['mrt-row-expand', 'requestid', 'firstname', 'lastname'] },
      pagination: { pageSize: 100, pageIndex: 0 }
    },
    muiPaginationProps: {
      shape: 'rounded',
      rowsPerPageOptions: [50, 100, 200],
      showFirstButton: true,
      showLastButton: true,
    },
    muiTableContainerProps: { sx: { height: '70vh' } },
    muiTableHeadCellProps: {
      sx: {
        '& .Mui-TableHeadCell-Content-Actions': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Actions': {
          display: "block"
        },
        '& .Mui-TableHeadCell-Content-Labels span': {
          display: "none"
        },
        '&:hover .Mui-TableHeadCell-Content-Labels span': {
          display: "block"
        },
      },
    },
    muiDetailPanelProps: () => ({
    }),
    //custom expand button rotation
    muiExpandButtonProps: ({ row, table }) => ({
      // onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),
  });

  return (
    <>
      <MaterialReactTable table={table} />

      <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
              {dialogText}
          </DialogContent>
          {dialogTitle === "Cancel PTO Request?" ? (
              <Stack style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                  <TextField
                      label="Reason"
                      onChange={(event) => setCancelReason(event.target.value)}
                  />
              </Stack>
          ) : (
              null
          )}
          <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                  Close
              </Button>
              {dialogTitle === "Request Confirmed!" ? (
                  null
              ) : (
                  <Button onClick={handleConfirmDialog} color="primary">
                      Confirm
                  </Button>
              )}
          </DialogActions>
      </Dialog>
    </>
  );
}