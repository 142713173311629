import React, { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Grid } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import frdarklogo from "../res/Spark.png";
import { Authenticated } from "../components/AuthenticatedComponent";
import { useIsAuthenticated } from "@azure/msal-react";

export function Home() {
  //eslint-disable-next-line
  const [releaseDate, setReleaseDate] = useState(new Date('6/3/24').toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }));

  const isAuthenticated = useIsAuthenticated();

  let isNewRelease = new Date() - new Date(releaseDate) <= 7 * 24 * 60 * 60 * 1000 ? true : false;
  const timeSheetRoles = ["Admin", "Service_TimeSheet_Manager", "Service_TimeSheet_User", "SI_TimeSheet_Manager", "SI_TimeSheet_User", "Payroll_Processors"];
  const serviceTSUserRole = ["Service_TimeSheet_User"];
  const adminRole = ["Admin"];

  return (
    <>
      <AuthenticatedTemplate>
        {isAuthenticated && (
          <Grid container spacing={2} style={{ padding: "30px" }}>
            {/* Left Grid */}
            <Grid item xs={6}>
              <Grid
                container
                justifyContent="center" // Center buttons vertically
                alignItems="center" // Center buttons horizontally
                style={{ height: "10%", paddingTop: "10%", marginBottom: "5%" }} // Center buttons vertically
              >
                <Typography
                  variant="h3"
                  alignItems="center"
                  fontFamily="monospace">
                  WELCOME TO&nbsp;
                </Typography>
                <Typography
                  color="red"
                  variant="h3"
                  alignItems="center"
                  fontFamily="monospace">
                  SPARK
                </Typography>
              </Grid>

              <Grid
                container
                direction="column"
                spacing={2}
                justifyContent="center" // Center buttons vertically
                alignItems="center" // Center buttons horizontally
                style={{ height: "90%" }} // Center buttons vertically
              >
                {Authenticated(serviceTSUserRole) === false || Authenticated(adminRole)
                  ? (
                  <>
                    <Grid item>
                      <Button
                        component={RouterLink}
                        to="/projectManagement"
                        variant="contained"
                        color="primary"
                        style={{ width: "100%" }}
                      >
                        Project Management
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        component={RouterLink}
                        to="/Manufacturing"
                        variant="contained"
                        color="primary"
                      >
                        Manufacturing
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        component={RouterLink}
                        to="/BayAssignment"
                        variant="contained"
                        color="primary"
                      >
                        Bay Assignment
                      </Button>
                    </Grid>
                    {/* <Grid item>
                      <Button
                        component={RouterLink}
                        to="/inventory"
                        variant="contained"
                        color="primary"
                      >
                        Inventory
                      </Button>
                    </Grid> */}
                    {/* <Grid item>
                      <Button
                        component={RouterLink}
                        to="/Site"
                        variant="contained"
                        color="primary"
                      >
                        Site Lookup
                      </Button>
                    </Grid> */}
                    {/* <Grid item>
                      <Button
                        component={RouterLink}
                        to="/Device"
                        variant="contained"
                        color="primary"
                      >
                        Device Lookup
                      </Button>
                    </Grid> */}
                    <Grid item>
                      <Button
                        component={RouterLink}
                        to="/WIP"
                        variant="contained"
                        color="primary"
                      >
                        WIP
                      </Button>
                    </Grid>
                  </>
                ) : (
                  null
                )}

                {Authenticated(timeSheetRoles)
                  ? (
                    <Grid item>
                      <Button
                        component={RouterLink}
                        to="/TimeSheet"
                        variant="contained"
                        color="primary"
                      >
                        Time Sheet
                      </Button>
                    </Grid>
                  ) : (
                    null
                  )}

              </Grid>
            </Grid>

            {/* Right Grid */}
            <Grid item xs={6} style={{ position: "relative" }}>
              {isNewRelease && (
                <Alert severity="info" style={{ position: "absolute", top: 0, left: 0, right: 0, zIndex: 2 }}>New Release!</Alert>
              )}

              <Accordion style={{
                position: "absolute",
                top: isNewRelease ? "2.5rem" : "0",
                left: 0,
                right: 0,
                zIndex: 1
              }}>

                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Release Notes: {releaseDate}
                </AccordionSummary>

                <AccordionDetails>
                  <Button
                    component={RouterLink}
                    to="/PatchNotes"
                    variant="contained"
                    color="primary"
                  >
                    🔥 Full Release Notes
                  </Button>
                  <br />
                  <br />
                  Many changes and fixes, please click the button above to view the full release notes.


                </AccordionDetails>
              </Accordion>

              <img
                src={frdarklogo}
                alt="Fire Rover Logo"
                style={{ width: "80%", borderRadius: "10%" }}
              />
            </Grid>
          </Grid>
        )}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Typography variant="h6">
          <center>Please sign-in to see your profile information.</center>
        </Typography>
      </UnauthenticatedTemplate>
    </>
  );
}