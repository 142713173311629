import React, { useState, useEffect } from "react";
import { Grid, Box, Stack, Typography, Button, Modal, TextField, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";
import moment from "moment";
import dayjs from "dayjs";
import MuiAlert from '@mui/material/Alert';
import TimeInput from "./TimeInput";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '30%',
    overflow: 'scroll',
    overflowX: "hidden",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const weekDays = [
    { day: "Sunday" },
    { day: "Monday" },
    { day: "Tuesday" },
    { day: "Wednesday" },
    { day: "Thursday" },
    { day: "Friday" },
    { day: "Saturday" },
];

export function TimeCell(cell) {

    const [open, setOpen] = useState(false);
    const [time, setTime] = useState(0);
    const [clockInTime, setClockInTime] = useState(null);
    const [clockOutTime, setClockOutTime] = useState(null);
    const [description, setDescription] = useState("");
    const [inProgTimeLog, setInProgTimeLog] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const dialogTitle = "Please enter a valid time.";
    const dialogText = "ex. 7:00 am, 7:00 AM, 7:00am, 7:00AM, 0700";
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    function getWeekRange(date) {
        return {
            from: moment(date)
                .startOf('week')
                .toDate(),
            to: moment(date)
                .endOf('week')
                .toDate(),
        };
    }

    useEffect(() => {
        handleSetInProgTimeLog();
    }, [cell.timeLogsList])
    
    useEffect(() => {
        const inProg = cell.timeLogsList.find((log) => (log.timesheetid === inProgTimeLog));
        inProg ? setClockInTime(dayjs(inProg.date.toISOString().slice(0, 11) + inProg.clockin + ".000Z")) : setClockInTime(null);
        // eslint-disable-next-line
    }, [inProgTimeLog])

    const handleSetInProgTimeLog = () => {
        const inProg = cell.timeLogsList.find((log) => 
            (log.userid === cell.employeeId && offsetDate(new Date(log.weekof)).getTime() === cell.weekOf.getTime() && weekdays[log.date.getDay()] === cell.day && log.clockin !== null && log.clockout === null));
        inProg ? setInProgTimeLog(inProg.timesheetid) : setInProgTimeLog(null);
    }

    const offsetDate = (date) => {
        return date ? new Date(date.getTime() + (date.getTimezoneOffset() * 60000)) : null;
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSubmitHours = (event) => {
        setLoading(true);

        const handleCleanUp = () => {
            if (cell.type === "Regular Work") {
                let timeAdjustment = 0;
                if (clockOutTime !== null) {
                    if (cell.currentTotal < 6 && cell.currentTotal + ((clockOutTime - clockInTime) / 3600000) >= 6) {
                        timeAdjustment -= 0.5;
                    }
                    setTime(time + +((clockOutTime - clockInTime) / 3600000).toFixed(2) + timeAdjustment);
                    setClockInTime(null);
                    setClockOutTime(null);
                    setDescription("");
                }
            }
        }

        const handleCalcHours = () => {
            let timeAdjustment = 0;
            if (clockInTime && clockOutTime) {
                if (cell.currentTotal < 6 && cell.currentTotal + ((clockOutTime - clockInTime) / 3600000) >= 6) {
                    timeAdjustment -= 0.5;
                }
                return (Number(((clockOutTime - clockInTime) / 3600000 + timeAdjustment).toFixed(2)));
            }
            return (0);
        }

        const getDateFromDay = () => {
            const i = weekdays.indexOf(cell.day);
            var weekStart = new Date(cell.weekOf);
            return moment(weekStart).add(i, 'days')
        }

        const getNextDateFromDay = () => {
            const i = weekdays.indexOf(cell.day);
            var weekStart = new Date(cell.weekOf);
            if (cell.day === "Saturday") {
                weekStart = new Date(cell.weekOf.getFullYear(), cell.weekOf.getMonth(), cell.weekOf.getDate()+7)
            }
            return moment(weekStart).add(i + 1, 'days')
        }

        if ((clockInTime && getWeekRange(new Date()).from.getTime() === cell.weekOf.getTime() && weekdays[new Date().getDay()] === cell.day) || (clockInTime && clockOutTime)) {

            let tempTimeLog = {}

            if (handleCalcHours() >= 0 && cell.currentTotal + handleCalcHours() <= 24) {
                if (inProgTimeLog === null) {
                    tempTimeLog = {
                        empUserId: cell.employeeId,
                        timesheetDate: getDateFromDay().toISOString().substring(0, 10),
                        clockIn: clockInTime ? new Date(clockInTime).toISOString().substring(11, 19) : null,
                        clockOut: clockOutTime ? new Date(clockOutTime).toISOString().substring(11, 19) : null,
                        hourType: cell.type,
                        hoursWorked: handleCalcHours(),
                        tsDescription: description ? description : "-",
                        tsDay: cell.day,
                        weekOf: new Date(cell.weekOf).toISOString().substring(0, 10)
                    };
                } 
                else {
                    tempTimeLog = {
                        timesheetId: inProgTimeLog,
                        empUserId: cell.employeeId,
                        timesheetDate: getDateFromDay().toISOString().substring(0, 10),
                        clockIn: clockInTime ? new Date(clockInTime).toISOString().substring(11, 19) : null,
                        clockOut: clockOutTime ? new Date(clockOutTime).toISOString().substring(11, 19) : null,
                        hourType: cell.type,
                        hoursWorked: handleCalcHours(),
                        tsDescription: description ? description : "-",
                        tsDay: cell.day,
                        weekOf: new Date(cell.weekOf).toISOString().substring(0, 10)
                    };
                }

                const newTimeLog = tempTimeLog;

                axios
                    .put("/api/TimeSheet/updateTimeLog", newTimeLog)
                    .then(() => {
                        setSnackbarOpen(true);
                    })
                    .catch((error) => {
                        console.error(error);
                        alert(error.response?.data || "Error creating Timesheet Log. Please try again.");
                    })
                    .finally(() => {
                        setLoading(false);
                        handleCleanUp();
                        handleClose();
                        cell.updateRow();
                    });
            } else if (handleCalcHours() < 0 && cell.currentTotal + (((dayjs(new Date().setHours(0,0,0,0)) - clockInTime) / 3600000) + 24) <= 24) {
                if (inProgTimeLog === null) {
                    tempTimeLog = {
                        empUserId: cell.employeeId,
                        timesheetDate: getDateFromDay().toISOString().substring(0, 10),
                        clockIn: clockInTime ? new Date(clockInTime).toISOString().substring(11, 19) : null,
                        clockOut: new Date(dayjs(new Date().setHours(0,0,0,0))).toISOString().substring(11, 19),
                        hourType: cell.type,
                        hoursWorked: (((dayjs(new Date().setHours(0,0,0,0)) - clockInTime) / 3600000) + 24).toFixed(2),
                        tsDescription: description ? description : "-",
                        tsDay: cell.day,
                        weekOf: new Date(cell.weekOf).toISOString().substring(0, 10)
                    };
                }
                else {
                    tempTimeLog = {
                        timesheetId: inProgTimeLog,
                        empUserId: cell.employeeId,
                        timesheetDate: getDateFromDay().toISOString().substring(0, 10),
                        clockIn: clockInTime ? new Date(clockInTime).toISOString().substring(11, 19) : null,
                        clockOut: new Date(dayjs(new Date().setHours(0,0,0,0))).toISOString().substring(11, 19),
                        hourType: cell.type,
                        hoursWorked: (((dayjs(new Date().setHours(0,0,0,0)) - clockInTime) / 3600000) + 24).toFixed(2),
                        tsDescription: description ? description : "-",
                        tsDay: cell.day,
                        weekOf: new Date(cell.weekOf).toISOString().substring(0, 10)
                    };
                };
                const newTimeLog = tempTimeLog;

                axios
                    .put("/api/TimeSheet/updateTimeLog", newTimeLog)
                    .then(() => {
                        setSnackbarOpen(true);
                    })
                    .catch((error) => {
                        console.error(error);
                        alert(error.response?.data || "Error creating Timesheet Log. Please try again.");
                    })
                    .finally(() => {
                        setLoading(false);
                        handleCleanUp();
                        handleClose();
                        cell.updateRow();
                    });

                tempTimeLog = {
                    empUserId: cell.employeeId,
                    timesheetDate: getNextDateFromDay().toISOString().substring(0, 10),
                    clockIn: new Date(dayjs(new Date().setHours(0,0,0,0))).toISOString().substring(11, 19),
                    clockOut: clockOutTime ? new Date(clockOutTime).toISOString().substring(11, 19) : null,
                    hourType: cell.type,
                    hoursWorked: ((clockOutTime - dayjs(new Date().setHours(0,0,0,0))) / 3600000).toFixed(2),
                    tsDescription: description ? description : "-",
                    tsDay: cell.day === "Saturday" ? "Sunday" : weekdays[weekdays.indexOf(cell.day) + 1],
                    weekOf: cell.day !== "Saturday" ? new Date(cell.weekOf).toISOString().substring(0, 10) : new Date(cell.weekOf.getFullYear(), cell.weekOf.getMonth(), cell.weekOf.getDate()+7).toISOString().substring(0, 10)
                };

                const nextTimeLog = tempTimeLog;

                axios
                    .post("/api/TimeSheet/createTimeLog", nextTimeLog)
                    .then(() => {
                        setSnackbarOpen(true);
                    })
                    .catch((error) => {
                        console.error(error);
                        alert(error.response?.data || "Error creating Timesheet Log. Please try again.");
                    })
                    .finally(() => {
                        setLoading(false);
                        handleCleanUp();
                        handleClose();
                        cell.updateRow();
                    });
            }
        } else {
            setLoading(false);
            setOpenDialog(true);
        }
    }

    useEffect(() => {
        if (cell.time > 0) {
            setTime(cell.time);
        }
    }, [cell])

    useEffect(() => {
        cell.setTime(time, cell.day, cell.type);
        // eslint-disable-next-line
    }, [time]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSnackbarClose = () => setSnackbarOpen(false);

    return (
        <>
            <Stack direction="row" minHeight="54px">
                {cell.type === "Regular Work"
                    ?
                    <Box>
                        <Button onClick={handleOpen} variant="contained" disabled={cell.denyChanges}>
                            <Stack marginY={"5px"} width="7vw">
                                <Typography>{cell.day}</Typography>
                                <Stack alignItems={"center"}>
                                    <Typography >
                                        {new Date(dayjs(cell.weekOf).add(weekDays.findIndex(d => d.day === cell.day), 'day')).getMonth()+1}/{new Date(dayjs(cell.weekOf).add(weekDays.findIndex(d => d.day === cell.day), 'day')).getDate()}
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Button>
                    </Box>
                    :
                    null
                }

                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <Box sx={style}>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                            style={{ position: "absolute", top: "10px", right: "10px" }}
                        >
                            X
                        </Button>

                        <Typography
                            variant="h6"
                            component="h2"
                            style={{ paddingBottom: "20px" }}
                        >
                            Input {cell.type} for {cell.day}
                        </Typography>
                        {cell.type === "Regular Work"
                            ?
                            <>
                                <Stack width={"100%"} marginBottom={"10px"} >
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimeInput
                                            label="Clock In Time"
                                            value={clockInTime}
                                            onChange={setClockInTime}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                                <Stack width={"100%"} marginBottom={"10px"}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimeInput
                                            label="Clock Out Time"
                                            value={clockOutTime}
                                            onChange={setClockOutTime}
                                            disabled={!clockInTime}
                                        />
                                    </LocalizationProvider>
                                </Stack>
                                <Stack width={"100%"} marginBottom={"15px"}>

                                    <TextField
                                        label="Description"
                                        value={description}
                                        onChange={(val) => setDescription(val.target.value)}
                                    />

                                </Stack>
                            </>
                            :
                            null
                        }

                        <Grid
                            container
                            justifyContent="flex-end"
                        >
                            <Button
                                style={{ color: "green" }}
                                onClick={handleSubmitHours}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Box>

                </Modal>
            </Stack>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    {dialogText}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
                    Time sheet saved!
                </MuiAlert>
            </Snackbar>
        </>
    )
}