import React, { useState, useEffect } from "react";
import { Box, Button, Stack, TextField, Typography, Snackbar} from "@mui/material";
import Switch from '@mui/material/Switch';
import { humanResourcesAxios as axios } from "../../utils/AxiosUtility";
import MuiAlert from '@mui/material/Alert';

export default function LoadPTO({ employee, onUpdatePto }) {

  const [totalPto, setTotalPto] = useState(0);
  const [resetPto, setResetPto] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (employee.totalPto !== "-") {
        setTotalPto(employee.totalPto);
    }
    // eslint-disable-next-line
  }, [employee]);

  const handleResetPto = () => {
    setResetPto(!resetPto);
  }

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleSave = () => {
    setLoading(true);

    var updatedPto
    if (resetPto) {
      updatedPto = {
        userId: employee.id,
        ptoTotal: totalPto ? totalPto : null,
        ptoPending: 0,
        ptoUsed: 0,
      };
      console.log(updatedPto);
    } else {
      updatedPto = {
        userId: employee.id,
        ptoTotal: totalPto ? totalPto : null,
        ptoPending: employee.pendingPto === "-" ? null : employee.pendingPto,
        ptoUsed: employee.usedPto === "-" ? null : employee.usedPto,
      };
    }

    axios
        .post('api/Employee/loadEmployeePto', updatedPto)
        .then((response) => {
          onUpdatePto();
            setSnackbarOpen(true);
        })
        .catch((error) => {
            console.log(error);
            if (error.response && error.response.data) {
                alert(error.response.data);
            } else {
                alert("Error updating Time Log. Please try again.");
            }
        })
        .finally(() => {
            setLoading(false);
            onUpdatePto();
        });
    
  }; 

  return (
    <Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        height: 275,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }}
    >
      <Button
        variant="outlined"
        onClick={onUpdatePto}
        style={{ position: 'absolute', top: '10px', right: '10px' }}
      >
        X
      </Button>

      <Typography id="modal-modal-title" variant="h6" component="h2" style={{ paddingBottom: "20px" }}>
        Load PTO for {employee.firstName} {employee.lastName}
      </Typography>

      <TextField
        label="Total PTO"
        value={totalPto}
        onChange={(e) => setTotalPto(Number(e.target.value))}
        style={{ width: "100%", marginBottom: "20px" }}
      />

      <Stack direction={"row"}>
        <Typography paddingTop={1}>Reset Available PTO to Maximum: </Typography>
        <Switch
          checked={resetPto}
          onChange={handleResetPto}
        />
      </Stack>
      

      <div style={{ position: 'absolute', bottom: '10px', right: '10px' }}>

        <Button
          style={{ color: "green", marginTop: "10px" }}
          onClick={handleSave}
          disabled={loading}
        >
          Save
        </Button>
      </div>

      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
          <MuiAlert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          PTO Loaded!
          </MuiAlert>
      </Snackbar>
    </Box>
  );
}